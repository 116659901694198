import request from '@/../utils/request'
const api_name = '/iotechserver/ycTypeTrans'

export default {
    getAllYcTypeTrans(current,limit,deviceId){
        let url = `${api_name}/getAllYcTypeTrans/${current}/${limit}`
        if(deviceId!=""){
            url += "?deviceId=" + deviceId
        }
        return request({
            url: url,
            method: 'get'
        })
    },
    addYcTypeTrans(obj){
        return request({
            url: `${api_name}/addYcTypeTrans`,
            method: 'post',
            data:obj
        })
    },
    updateYcTypeTrans(obj){
        return request({
            url: `${api_name}/updateYcTypeTrans`,
            method: 'post',
            data:obj
        })
    },
    deleteYcTypeTrans(deviceId){
        return request({
            url: `${api_name}/deleteYcTypeTrans/${deviceId}`,
            method: 'get'
        })
    }
}