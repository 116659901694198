<template>
  <div id="app">
    <div style="position: relative;display:flex;justify-content: "> 
        <div class="item">
            <el-input style="margin-left:5px" v-model="deviceId" placeholder="请输入设备编号" clearable></el-input>
        </div>
         <div class="item">
            <el-button type="primary" @click="function(){current=1;getAllYcTypeTrans()}">搜索</el-button>
        </div>
         <div class="item">
            <el-button type="primary" @click="function(){dialogVisible=true;title='添加设备';deviceInfo.ycPlace='';deviceInfo.type=''}">添加设备</el-button>
        </div>
    </div>

    <div class="table">
         <el-table :data="tableList" style="width: 100%" border :max-height="700">
      <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
      <el-table-column prop="ycPlace" label="设备编号" align="center">
      </el-table-column>
      <el-table-column prop="type" label="编码类型" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="250">
        <template slot-scope="scope">
          <el-button style="margin-right:5px" 
          @click="function(){dialogVisible=true;title='修改设备';deviceInfo.id=scope.row.id;deviceInfo.ycPlace=scope.row.ycPlace;deviceInfo.type=scope.row.type}"
           type="success" size="small">修改</el-button>
          <el-popconfirm
            confirm-button-text='好的'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="确认删除该设备吗"
            @confirm="deleteYcTypeTrans(scope.row)"
            >
            <el-button size="small" slot="reference">删除</el-button>
        </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination :current-page.sync="current" :page-size="limit" background :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="getAllYcTypeTrans()"></el-pagination>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="30%">
        <el-form :model="deviceInfo" label-width="80px">
            <el-form-item label="设备ID">
                <el-input v-model="deviceInfo.ycPlace"></el-input>
            </el-form-item>
            <el-form-item label="编码类型">
                <el-input v-model="deviceInfo.type"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="title=='添加设备'?addYcTypeTrans():updateYcTypeTrans()">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import YcDeviceTypeManage from '@/api/manageApi/YcDeviceTypeManage'
export default {
  data() {
    return {
        tableList:[],//表格数据
        current:1,
        limit:10,
        total:0,
        deviceId:"",//设备id
        dialogVisible:false,//是否显示弹框
        deviceInfo:{
            id:"",
            ycPlace:"",
            type:""
        },
        title:"添加设备",//弹框标题
    };
  },
  created() {
    this.getAllYcTypeTrans();
  },
  methods: {
    //获取所有需要转换的用传号
    getAllYcTypeTrans(){
        YcDeviceTypeManage.getAllYcTypeTrans(this.current,this.limit,this.deviceId).then(res=>{
            this.tableList = res.data.data.list;
            this.total = res.data.data.total
        })
    },
    //添加需要转换的用传号
    addYcTypeTrans(){
        if(this.deviceInfo.ycPlace==""||this.deviceInfo.type==""){
            this.$message({
                type:"warning",
                message:"请填写所有信息"
            })
            return
        }else if(this.deviceInfo.type.length>2){
            this.$message({
                type:"warning",
                message:"类型长度不能超过2"
            })
            return
        }
        let obj = {}
        obj.ycPlace = this.deviceInfo.ycPlace
        obj.type = this.deviceInfo.type
        YcDeviceTypeManage.addYcTypeTrans(obj).then(res=>{
            if(res.data.message=="添加成功"){
                this.dialogVisible = false
                this.$message({
                    type:"success",
                    message:"添加成功"
                })
                this.current = 1
                this.deviceInfo = {ycPlace:"",type:""}
                this.getAllYcTypeTrans()
            }else{
                this.$message({
                    type:"error",
                    message:"添加失败"
                })
            }
        })
    },
    //修改需要转换的用传号
    updateYcTypeTrans(){
        if(this.deviceInfo.ycPlace==""||this.deviceInfo.type==""){
            this.$message({
                type:"warning",
                message:"请填写所有信息"
            })
            return
        }else if(this.deviceInfo.type.length>2){
            this.$message({
                type:"warning",
                message:"类型长度不能超过2"
            })
            return
        }
        let obj = {}
        obj.id = this.deviceInfo.id
        obj.ycPlace = this.deviceInfo.ycPlace
        obj.type = this.deviceInfo.type
        YcDeviceTypeManage.updateYcTypeTrans(obj).then(res=>{
            if(res.data.message=="修改成功"){
                this.dialogVisible = false
                this.$message({
                    type:"success",
                    message:"修改成功"
                })
                this.deviceInfo = {ycPlace:"",type:""}
                this.getAllYcTypeTrans()
            }else{
                this.$message({
                    type:"error",
                    message:"修改失败"
                })
            }
        })
    },
    //删除需要转换的用传号
    deleteYcTypeTrans(row){
        YcDeviceTypeManage.deleteYcTypeTrans(row.ycPlace).then(res=>{
            if(res.data.message=="删除成功"){
                this.$message({
                    type:"success",
                    message:"删除成功"
                })
                this.getAllYcTypeTrans()
            }else{
                this.$message({
                    type:"error",
                    message:"删除失败"
                })
            }
        })
    },
  },
};
</script>

<style scoped lang="scss">
.rows {
  margin: 10px 10px 10px 0;
}
.item {
  margin: 20px 5px;
}
</style>